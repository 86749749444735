import { useState, useRef } from "react";
import { Document, Page } from "react-pdf";
import { pdfjs } from "react-pdf";
// import { PDFViewer } from "react-pdf";

import "react-pdf/dist/Page/TextLayer.css";
pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  "pdfjs-dist/build/pdf.worker.min.mjs",
  import.meta.url
).toString();

// pdfjs.GlobalWorkerOptions.workerSrc = new URL(
//   "pdfjs-dist/build/pdf.worker.min.mjs",
//   import.meta.url
// ).toString();
const PdfViewer = ({
  url,
  setPdf,
  setPdfLoad,
  numPages,
  pageNumber,
  setNumPages,
  setPageNumber,
}) => {
  const [viewportSize, setViewportSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  const canvasRef = useRef(null);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const goToPrevPage = () => {
    setPageNumber(pageNumber - 1 <= 1 ? 1 : pageNumber - 1);
    console.log(pageNumber);
  };

  const goToNextPage = () => {
    setPageNumber(pageNumber + 1 <= numPages ? pageNumber + 1 : "");
    console.log(pageNumber);
    console.log(numPages);
  };
  const scale = Math.min(
    viewportSize.width / 800, // Replace 800 with the width of your PDF
    viewportSize.height / 1000 // Replace 1000 with the height of your PDF
  );
  const onPageRenderSuccess = (page) => {
    // The canvas element will be accessible after the page renders
    const canv = document.body.getElementsByClassName(
      "react-pdf__Page__canvas"
    )[0];
    const width = canv.offsetWidth;
    console.log(width);
    console.log(canv);
    const canvas = canvasRef.current;
    setPdf({ height: viewportSize.height, width: width });
    setPdfLoad(true);
    console.log(viewportSize.height);
    console.log(canvas.width);
  };
  return (
    <div style={{ height: "50vh", width: "auto" }}>
      <nav
        style={{
          position: "absolute",
          zIndex: "2000",
          margin: "auto",
          width: "100%",
        }}
        className="text-center"
      >
        <button
          onClick={goToPrevPage}
          className="btn btn-primary mx-2"
          disabled={pageNumber === 1}
        >
          Prev
        </button>
        <button
          onClick={goToNextPage}
          className="btn btn-primary mx-2"
          disabled={pageNumber === numPages}
        >
          Next
        </button>
        <p>
          Page {pageNumber} of {numPages}
        </p>
      </nav>
      {/* <PDFViewer width="auto" height="100%"> */}

      <Document file={url} onLoadSuccess={onDocumentLoadSuccess}>
        <Page
          pageNumber={pageNumber}
          canvasRef={canvasRef}
          onRenderSuccess={onPageRenderSuccess}
          renderAnnotationLayer={false}
          renderTextLayer={false}
          height={viewportSize.height}
        />
      </Document>
      {/* </PDFViewer> */}
    </div>
  );
};

export default PdfViewer;
