import { useState } from "react";
import "./App.css";
import ForgotPassword from "./Pages/ForgotPassword";
import HomePage from "./Pages/Homepage";
import LoginPage from "./Pages/LoginPage";
import PhoneNumberVerification from "./Pages/PhoneNumberVerification";
import SignupPage from "./Pages/SignupPage";
import Success from "./Pages/Success";
import Uploader from "./Pages/Uploader";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import ResetPassword from "./Pages/ResetPassword";
const rootUrl = "/signin";

function App() {
  const [authorised, setAuthorised] = useState(false);
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<LoginPage auth={authorised} />} />
          <Route
            path={rootUrl}
            element={<SignupPage auth={authorised} setAuth={setAuthorised} />}
          />
          <Route path="/homepage" element={<HomePage />} />
          <Route path="/uploader" element={<Uploader />} />
          <Route path="/password_recovery" element={<ForgotPassword />} />
          <Route path="/verification" element={<PhoneNumberVerification />} />
          <Route path="/successfull" element={<Success auth={authorised} />} />
          <Route path="/reset-password" element={<ResetPassword />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
