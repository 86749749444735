// src/PasswordStrengthIndicator.js
import React, { useEffect } from 'react';
import './PasswordStrengthIndicator.css';

const PasswordStrengthIndicator = ({ password, setPassword, strength, setStrength }) => {
  const evaluatePasswordStrength = (password) => {
    if (password.length === 0) return '';

    let strength = 0;
    if (password.length > 5) strength += 1;
    if (password.length > 7) strength += 1;
    if (/[A-Z]/.test(password)) strength += 1;
    if (/[a-z]/.test(password)) strength += 1;
    if (/[0-9]/.test(password)) strength += 1;
    if (/[^A-Za-z0-9]/.test(password)) strength += 1;

    switch (strength) {
      case 0:
        return '';
      case 1:
        return 'Weak';
      case 2:
        return 'Weak';
      case 3:
        return 'Fair';
      case 4:
        return 'Good';
      case 5:
        return 'Strong';
      case 6:
        return 'Very Strong';
      default:
        return '';
    }
  };

  const unhide = () => {
    const bar = document.body.getElementsByClassName("strength-bar")[0];
    const message = document.body.getElementsByClassName("strength-message")[0];
    if (password.length > 0) {
      bar.removeAttribute("hidden");
      message.removeAttribute("hidden");
    } else {
      bar.setAttribute("hidden", true);
      message.setAttribute("hidden", true);
    }
  };

  useEffect(() => {
    setStrength(evaluatePasswordStrength(password));
    unhide();
  }, [password]);

  const handlePasswordChange = (e) => {
    const newPassword = e.target.value;
    setPassword(newPassword);
  };

  return (
    <div className="password-strength-indicator" style={{ position: "relative" }}>
      <label htmlFor="message" style={{ position: "relative", top: "11px", left: "10px", background: "#FFFFFF", width: "fit-content" }}>Password</label>
      <input
        className="form-control"
        type="password"
        placeholder="Enter your Password"
        style={{ height: "50px", border: "1px solid #6C6A6A85" }}
        value={password}
        onChange={handlePasswordChange}
        width={"100%"}
      />
      <div className={`strength-bar ${strength.toLowerCase().replace(' ', '-')}`} hidden></div>
      <div className="strength-message" hidden>{strength}</div>
    </div>
  );
};

export default PasswordStrengthIndicator;
