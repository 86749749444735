import React, { useEffect, useState } from "react";
import Navbar from "../Components/Navbar.js";
import Sidebar from "../Components/Sidebar.js";
import { useNavigate } from "react-router-dom";
import Dashbaord from "../Components/DashboardBody.js";
import AssetsBody from "../Components/AssetsBody.js";
import ConnectorBody from "../Components/ConnectorBody.js";
import AuditlogBody from "../Components/AuditlogBody.js";
import Cookies from "js-cookie";
import axios from "axios";
import HumanValidation from "../Components/Human.js";
function HomePage() {
  const [currentState, setState] = useState("dashboard");
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 1000);
  const [isClicked, setIsClicked] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    // const storedData = localStorage.getItem("token");
    // if (storedData == null) {
    //   navigate("/");
    // }
    if (!Cookies.get("access_token")) {
      navigate("/");
    }
  }, []);
  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 1000);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return (
    <>
      <div style={styles.appContainer} className="main">
        <Sidebar
          State={currentState}
          setState={setState}
          mobile={isMobile}
          clicked={isClicked}
          setClicked={setIsClicked}
        />

        <div
          style={{
            height: "100vh",
            width: "100%",
            paddingLeft: "6rem",
            opacity: isClicked ? "0.4" : "1",
          }}
          className="d-flex flex-column"
        >
          <Navbar
            setMobile={setIsMobile}
            mobile={isMobile}
            setClicked={setIsClicked}
          />
          <div
            style={{
              height: "100%",
              background: "#FFFFFF",
              // border: "1px solid #E7E7E7",
              width: "100%",
            }}
            className="d-flex flex-column"
          >
            <div
              style={{ height: "100%" }}
              className="d-flex justify-content-center"
            >
              <div
                style={{
                  height: "100%",
                  width: "100%",
                  paddingLeft: "5%",
                  paddingRight: "5%",
                }}
              >
                {currentState == "dashboard" && <Dashbaord mobile={isMobile} />}
                {currentState == "config" && <AssetsBody mobile={isMobile} />}
                {currentState == "upload" && <ConnectorBody />}
                {currentState == "output" && <AuditlogBody mobile={isMobile} />}
                {currentState == "human" && (
                  <HumanValidation mobile={isMobile} />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
const styles = {
  appContainer: {
    display: "flex",
    // flexDirection: "column",
    height: "100vh",
  },
  contentContainer: {
    marginLeft: "250px", // Adjust to match the sidebar width
    flex: 1,
    paddingTop: "60px", // Adjust to match the navbar height
    overflowX: "hidden", // Prevent horizontal overflow
  },
  mainContent: {
    padding: "20px",
  },
};
export default HomePage;
