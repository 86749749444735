import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import axios from "axios";
import "../Css/SignupPage.css";
import "react-phone-input-2/lib/style.css";
import "./styles.css";
import PasswordStrengthIndicator from "../Utilities/PasswordStrength";
import { getApiUrl } from "../Utilities/api";
export default function SignupPage({ auth, setAuth }) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const change = () => {
    navigate(`/`);
  };
  const [verificationCode, setVerificationCode] = useState("");
  const [confirmationResult, setConfirmationResult] = useState(null);
  const [status, setStatus] = useState(null);
  const [value, setValue] = useState("");
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [strength, setStrength] = useState("");
  const [contactNumber, setContactNumber] = useState("");
  const [verified, setVerified] = useState(false);
  const [user, setUser] = useState(null);
  const [otp, setOtp] = useState("");
  // const [loading, setLoading] = useState(false);
  const handleOtp = (e) => {
    setOtp(e.target.value);
    console.log(otp);
  };
  const handleName = (e) => {
    setFullName(e.target.value);
    // console.log(fullName)
  };
  const handleEmail = (e) => {
    setEmail(e.target.value);
    // console.log(fullName)
  };
  const handlePassword = (e) => {
    setPassword(e.target.value);
    // console.log(fullName)
  };
  const isFormValid = () => {
    return fullName.trim() !== "" && email.trim() !== "";
  };
  const validatePassword = (password) => {
    return {
      length: password.length >= 8,
      uppercase: /[A-Z]/.test(password),
      lowercase: /[a-z]/.test(password),
      number: /\d/.test(password),
      special: /[!@#$%^&*(),.?":{}|<>]/.test(password),
    };
  };
  const passwordCriteria = validatePassword(password);
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!isFormValid()) {
      const PasswordCriteria =
        document.body.getElementsByClassName("PasswordCriteria")[0];
      PasswordCriteria.setAttribute("hidden", "true");
      const alert = document.body.getElementsByClassName("alert")[0];
      alert.innerHTML = "Please fill out all fields";
      alert.removeAttribute("hidden");
      setTimeout(() => {
        alert.setAttribute("hidden", "true");
      }, 4000);
    } else if (strength !== "Strong" && strength !== "Very Strong") {
      const alert = document.body.getElementsByClassName("alert")[0];
      alert.innerHTML = "Password is not Strong enough";
      alert.removeAttribute("hidden");
      setTimeout(() => {
        alert.setAttribute("hidden", "true");
      }, 4000);
      console.log(strength);
    } else {
      try {
        const PasswordCriteria =
          document.body.getElementsByClassName("PasswordCriteria")[0];
        PasswordCriteria.setAttribute("hidden", "true");
        const myHeaders = new Headers();
        const alert = document.body.getElementsByClassName("alert")[0];
        const loader = document.body.getElementsByClassName("loader")[0];
        const mainBody = document.body.getElementsByClassName("mainPage")[0];
        const bodyData = { name: fullName, email: email, password: password };
        myHeaders.append("Content-Type", "application/json");
        mainBody.style.opacity = "0.3";
        loader.removeAttribute("hidden");
        console.log(password);
        console.log(strength);
        const response = await axios.post(
          getApiUrl("auth/register"),
          bodyData,
          { myHeaders }
        );
        localStorage.setItem("mail", email);
        mainBody.style.opacity = "1";
        loader.setAttribute("hidden", "true");
        console.log("Response: ", response.data);
        if (response.data.error) {
          alert.innerHTML = response.data.error;
          alert.removeAttribute("hidden");
          setTimeout(() => {
            alert.setAttribute("hidden", "true");
          }, 3000);
        } else {
          alert.innerHTML = response.data.message + ". Go to the login page";

          alert.removeAttribute("hidden");
          setTimeout(() => {
            alert.setAttribute("hidden", "true");
          }, 3000);
          setAuth(true);
          navigate("/successfull");
        }
      } catch (error) {
        const alert = document.body.getElementsByClassName("alert")[0];
        alert.setAttribute("class", "alert alert-danger alert");
        // if(error.response.statusText)
        // {
        //     alert.innerHTML = error.response.statusText
        // }
        if (error.response.data.detail) {
          alert.innerHTML = error.response.data.detail;
        } else {
          alert.innerHTML = "Request timed out";
        }
        const loader = document.body.getElementsByClassName("loader")[0];
        const mainBody = document.body.getElementsByClassName("mainPage")[0];
        mainBody.style.opacity = "1";
        loader.setAttribute("hidden", "true");
        alert.removeAttribute("hidden");
        setTimeout(() => {
          alert.setAttribute("hidden", "true");
          alert.setAttribute("class", "alert alert-primary alert");
        }, 3000);
        console.log("Error Signing up: ", error);
      }
    }
  };
  useEffect(() => {
    const PasswordCriteria =
      document.body.getElementsByClassName("PasswordCriteria")[0];
    if (password.length > 0) {
      PasswordCriteria.removeAttribute("hidden");
    } else {
      PasswordCriteria.setAttribute("hidden", "true");
    }
  }, [password]);
  useEffect(() => {
    setAuth(false);
    if (localStorage.getItem("token")) {
      navigate("/homepage");
    }
  }, []);
  return (
    <>
      <div
        style={{
          position: "absolute",
          height: "100vh",
          width: "100vw",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div className="fs-3 loader" style={{ position: "absolute" }} hidden />
        <div
          className="alert alert-primary alert"
          role="alert"
          style={{ position: "absolute", top: "0", zIndex: "1000" }}
          hidden
        >
          A simple primary alert—check it out!
        </div>
        {/* <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 200 200"
          height={"200px"}
        >
          <circle
            fill="#FF156D"
            stroke="#FF156D"
            strokeWidth="15"
            r="15"
            cx="40"
            cy="65"
          >
            <animate
              attributeName="cy"
              calcMode="spline"
              dur="2"
              values="65;135;65;"
              keySplines=".5 0 .5 1;.5 0 .5 1"
              repeatCount="indefinite"
              begin="-.4"
            ></animate>
          </circle>
          <circle
            fill="#FF156D"
            stroke="#FF156D"
            strokeWidth="15"
            r="15"
            cx="100"
            cy="65"
          >
            <animate
              attributeName="cy"
              calcMode="spline"
              dur="2"
              values="65;135;65;"
              keySplines=".5 0 .5 1;.5 0 .5 1"
              repeatCount="indefinite"
              begin="-.2"
            ></animate>
          </circle>
          <circle
            fill="#FF156D"
            stroke="#FF156D"
            strokeWidth="15"
            r="15"
            cx="160"
            cy="65"
          >
            <animate
              attributeName="cy"
              calcMode="spline"
              dur="2"
              values="65;135;65;"
              keySplines=".5 0 .5 1;.5 0 .5 1"
              repeatCount="indefinite"
              begin="0"
            ></animate>
          </circle>
        </svg> */}
      </div>
      {/* <div
        className="d-flex justify-content-center align-items-center mainPage"
        style={{ height: "100vh", position: "relative" }}
      >
        <div
          className="alert alert-danger alert"
          role="alert"
          style={{ position: "absolute", top: "0", zIndex: "3" }}
          hidden
        >
          A simple primary alert—check it out!
        </div>
        <div className="container mt-5" style={{ width: "70vw" }}>
          <div className="row justify-content-center">
            <div className="col-md-6">
              <div className="card">
                <div
                  className="card-body d-flex justify-content-center flex-column align-items-center"
                  style={{
                    padding: "0",
                    borderRadius: "6px",
                    boxShadow: "20px 20px 20px rgba(0, 0, 0, 0.2)",
                    border: "1px solid rgba(0, 0, 0, 0.2)",
                  }}
                >
                  <div
                    style={{
                      textAlign: "center",
                      height: "100%",
                      width: "200px",
                    }}
                    className="d-flex align-items-center justify-content-center"
                  >
                    <img
                      src="https://doc-forensics-assests.s3.ap-south-1.amazonaws.com/logo.jpeg"
                      height={"90px"}
                      style={{ marginTop: "10px" }}
                    ></img>
                  </div>
                  <h3 className="my-3">
                    <b>Create Account</b>
                  </h3>
                  <form style={{ width: "70%" }} onSubmit={handleSubmit}>
                    <div
                      className="form-group input-container"
                      style={{ position: "relative" }}
                    >
                      <label
                        htmlFor="name"
                        style={{
                          position: "relative",
                          top: "11px",
                          left: "10px",
                          background: "#FFFFFF",
                        }}
                      >
                        Full Name
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="name"
                        placeholder="Enter your name"
                        style={{
                          height: "50px",
                          border: "1px solid #6C6A6A85",
                        }}
                        value={fullName}
                        onChange={handleName}
                      />
                    </div>
                    <div
                      className="form-group input-container"
                      style={{ position: "relative" }}
                    >
                      <label
                        htmlFor="email"
                        style={{
                          position: "relative",
                          top: "11px",
                          left: "10px",
                          background: "#FFFFFF",
                        }}
                      >
                        Email
                      </label>
                      <input
                        type="email"
                        className="form-control"
                        id="email"
                        placeholder="Enter your email"
                        style={{
                          height: "50px",
                          border: "1px solid #6C6A6A85",
                        }}
                        value={email}
                        onChange={handleEmail}
                      />
                    </div>
                    <div style={{ position: "relative" }}>
                      <PasswordStrengthIndicator
                        password={password}
                        setPassword={setPassword}
                        strength={strength}
                        setStrength={setStrength}
                      />
                      <div
                        class="card PasswordCriteria"
                        style={{
                          width: "18rem",
                          position: "absolute",
                          right: "-313px",
                          top: "-93px",
                        }}
                      >
                        <div class="card-body">
                          <div
                            style={{
                              position: "absolute",
                              left: "-9px",
                              top: "50%",
                              transform: "translateY(-50%)",
                              width: "0",
                              height: "0",
                              borderTop: "10px solid transparent",
                              borderBottom: "10px solid transparent",
                              borderRight: "10px solid #007bff",
                            }}
                          ></div>
                          <h3 class="card-subtitle mb-2 text-body-secondary">
                            Password Criteria
                          </h3>
                          <ul>
                            <li
                              className={
                                passwordCriteria.length ? "text-success" : ""
                              }
                            >
                              At least 8 characters
                            </li>
                            <li
                              className={
                                passwordCriteria.uppercase ? "text-success" : ""
                              }
                            >
                              At least one uppercase letter
                            </li>
                            <li
                              className={
                                passwordCriteria.lowercase ? "text-success" : ""
                              }
                            >
                              At least one lowercase letter
                            </li>
                            <li
                              className={
                                passwordCriteria.number ? "text-success" : ""
                              }
                            >
                              At least one number
                            </li>
                            <li
                              className={
                                passwordCriteria.special ? "text-success" : ""
                              }
                            >
                              At least one special character
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>

                    <div className="d-flex justify-content-center">
                      <button
                        type="submit"
                        className="btn btn-dark fs-5"
                        style={{ width: "170px", marginTop: "20px" }}
                      >
                        <b>SIGN UP</b>
                      </button>
                    </div>
                  </form>
                  <div className="my-3">
                    <p style={{ display: "inline" }}>
                      if you already have an account?
                    </p>
                    <p
                      style={{
                        color: "#2D60FF",
                        display: "inline",
                        cursor: "pointer",
                      }}
                      onClick={change}
                    >
                      <u>
                        <b> Login Now</b>
                      </u>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      <div
        className="d-flex justify-content-between align-items-center mainPage"
        style={{ height: "100vh", position: "relative" }}
      >
        <div style={{ width: "50vw", height: "100vh" }}>
          <div className="card" style={{ height: "100vh" }}>
            <div
              className="card-body d-flex justify-content-center flex-column align-items-center"
              style={{
                padding: "0",
                borderRadius: "6px",
                boxShadow: "20px 20px 20px rgba(0, 0, 0, 0.2)",
                border: "1px solid rgba(0, 0, 0, 0.2)",
              }}
            >
              <div
                style={{
                  textAlign: "center",
                  height: "fit-content",
                  width: "200px",
                }}
                className="d-flex align-items-center justify-content-center"
              >
                <img
                  src="https://doc-forensics-assests.s3.ap-south-1.amazonaws.com/logo.jpeg"
                  height={"90px"}
                  style={{ marginTop: "10px" }}
                ></img>
              </div>
              <h3 className="my-3">
                <b>Sign Up To Your Account</b>
              </h3>
              <form style={{ width: "70%" }} onSubmit={handleSubmit}>
                <div
                  className="form-group input-container"
                  style={{ position: "relative" }}
                >
                  <label
                    htmlFor="name"
                    style={{
                      position: "relative",
                      top: "11px",
                      left: "10px",
                      background: "#FFFFFF",
                    }}
                  >
                    Full Name
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="name"
                    placeholder="Enter your name"
                    style={{
                      height: "50px",
                      border: "1px solid #6C6A6A85",
                    }}
                    value={fullName}
                    onChange={handleName}
                  />
                </div>
                <div
                  className="form-group input-container"
                  style={{ position: "relative" }}
                >
                  <label
                    htmlFor="email"
                    style={{
                      position: "relative",
                      top: "11px",
                      left: "10px",
                      background: "#FFFFFF",
                    }}
                  >
                    Email
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    id="email"
                    placeholder="Enter your email"
                    style={{
                      height: "50px",
                      border: "1px solid #6C6A6A85",
                    }}
                    value={email}
                    onChange={handleEmail}
                  />
                </div>
                <div style={{ position: "relative" }}>
                  <PasswordStrengthIndicator
                    password={password}
                    setPassword={setPassword}
                    strength={strength}
                    setStrength={setStrength}
                  />
                  <div
                    class="card PasswordCriteria"
                    style={{
                      width: "18rem",
                      position: "absolute",
                      right: "-313px",
                      top: "-93px",
                    }}
                  >
                    <div class="card-body">
                      <div
                        style={{
                          position: "absolute",
                          left: "-9px",
                          top: "50%",
                          transform: "translateY(-50%)",
                          width: "0",
                          height: "0",
                          borderTop: "10px solid transparent",
                          borderBottom: "10px solid transparent",
                          borderRight: "10px solid #007bff",
                        }}
                      ></div>
                      <h3 class="card-subtitle mb-2 text-body-secondary">
                        Password Criteria
                      </h3>
                      <ul>
                        <li
                          className={
                            passwordCriteria.length ? "text-success" : ""
                          }
                        >
                          At least 8 characters
                        </li>
                        <li
                          className={
                            passwordCriteria.uppercase ? "text-success" : ""
                          }
                        >
                          At least one uppercase letter
                        </li>
                        <li
                          className={
                            passwordCriteria.lowercase ? "text-success" : ""
                          }
                        >
                          At least one lowercase letter
                        </li>
                        <li
                          className={
                            passwordCriteria.number ? "text-success" : ""
                          }
                        >
                          At least one number
                        </li>
                        <li
                          className={
                            passwordCriteria.special ? "text-success" : ""
                          }
                        >
                          At least one special character
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

                <div className="d-flex justify-content-center">
                  <button
                    type="submit"
                    className="btn fs-5"
                    style={{
                      width: "170px",
                      marginTop: "20px",
                      backgroundColor: "#039AC2",
                      color: "white",
                    }}
                  >
                    <b>SIGN UP</b>
                  </button>
                </div>
              </form>
              <div className="my-3">
                <p style={{ display: "inline" }}>
                  if you already have an account?
                </p>
                <p
                  style={{
                    color: "#039AC2",
                    display: "inline",
                    cursor: "pointer",
                  }}
                  onClick={change}
                >
                  <b> Login Now</b>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            background: "linear-gradient(to bottom, #0b1d31, #039AC2)", // Gradient background
            height: "100vh",
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            color: "white",
            textAlign: "center",
            padding: "20px",
            boxSizing: "border-box",
          }}
        >
          <div
            style={{
              maxWidth: "100%",
              margin: "0 auto",
              padding: "20px",
              borderRadius: "10px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <h1
              style={{
                fontWeight: "bold",
                fontSize: "24px",
                marginBottom: "20px",
              }}
            >
              <span style={{ color: "#1bb1e7" }}>Efficient</span> Document{" "}
              <span style={{ color: "#1bb1e7" }}>Data Extraction </span>With Us
            </h1>
            <p
              style={{
                fontSize: "16px",
                lineHeight: "1.5",
                marginBottom: "20px",
              }}
            >
              Automate and streamline your document processing. Extract data
              from various document types with accuracy and speed, reducing
              manual errors and increasing productivity.
            </p>
            <ul
              style={{
                listStyle: "none",
                padding: 0,
                margin: "0 0 20px 0",
                fontSize: "16px",
                width: "100%",
                display: "flex",
                alignItems: "center",
              }}
            >
              <li
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  padding: "10px",
                  borderRadius: "10px",
                  marginBottom: "10px",
                }}
              >
                <span>
                  <b>📄 Extract Data in Seconds</b> <br></br>Quickly process
                  invoices, receipts, contracts, and more.
                </span>
              </li>
              <li
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  padding: "10px",
                  borderRadius: "10px",
                  marginBottom: "10px",
                }}
              >
                <span style={{ textAlign: "center" }}>
                  🔍<b className="text-center"> Accurate Data Recognition</b>
                  <br /> AI-driven technology ensures precise data extraction.
                </span>
              </li>
              <li
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  padding: "10px",
                  borderRadius: "10px",
                  marginBottom: "10px",
                }}
              >
                <span>
                  <b>📊 Flexible Integration</b>
                  <br></br> Easily integrate with your existing systems.
                </span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}
