import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { getApiUrl } from "../Utilities/api";

const ResetPassword = () => {
  const [loading, setloading] = useState(false);
  const [alert, setAlert] = useState(false);
  const [alertBody, setAlertBody] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const location = useLocation(); // Extract the location object
  const navigate = useNavigate(); // For navigation after successful reset

  // Function to extract query parameters (email and token)
  const getQueryParams = () => {
    const params = new URLSearchParams(location.search);
    const email = params.get("email");
    const token = params.get("token");
    return { email, token };
  };

  // Extract email and token from the URL
  const { email, token } = getQueryParams();

  // Handle form submission
  const handleSubmit = async (event) => {
    event.preventDefault();

    // Basic validation: Check if passwords match
    if (password !== confirmPassword) {
      setAlertBody("Password doesn't match");
      setAlert(true);
      setTimeout(() => {
        setAlert(false);
      }, 2000);
      return;
    }

    try {
      setloading(true);
      // Send new password to the server
      const queryParams = `token=${token}&password=${password}`;
      const response = await axios.post(
        getApiUrl("auth/reset-password?" + queryParams),
        {}
      );

      // Redirect to login page after successful reset
      console.log(response);
      setloading(false);
      setAlertBody("Success");
      setAlert(true);
      setTimeout(() => {
        setAlert(false);
        navigate("/");
      }, 3000);
    } catch (error) {
      setloading(false);
      setAlertBody(error.response.data.detail);
      setAlert(true);
      setTimeout(() => {
        setAlert(false);
      }, 2000);
      console.error("Error resetting password", error);
    }
  };
  const backToLogin = () => {
    navigate("/");
  };
  return (
    <>
      <p
        style={{
          position: "absolute",
          top: "12px",
          left: "24px",
          cursor: "pointer",
          zIndex: "2000",
        }}
        className="fs-4"
        onClick={backToLogin}
      >
        <u>&lt; Login Page</u>
      </p>
      <div
        style={{
          position: "absolute",
          height: "100vh",
          width: "100vw",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          className="fs-3 loader"
          style={{ position: "absolute", zIndex: "1000" }}
          hidden={!loading}
        />
        <div
          className="alert alert-primary alert"
          role="alert"
          style={{ position: "absolute", top: "0", zIndex: "1000" }}
          hidden={!alert}
        >
          {alertBody}
        </div>
      </div>
      <div
        style={{
          height: "100vh",
          width: "100vw",
          opacity: "0.7",
          background: "white",
          position: "absolute",
          zIndex: "888",
        }}
        hidden={!loading}
      ></div>
      <div
        style={{ height: "100vh" }}
        className="d-flex align-items-center justify-content-center"
      >
        <div className="col-md-3">
          <div className="card">
            <div
              className="card-body d-flex justify-content-center flex-column align-items-center px-2"
              style={{
                padding: "0",
                borderRadius: "6px",
                boxShadow: "0px 20px 40px rgba(0, 0, 0, 0.2)",
                border: "1px solid rgba(0, 0, 0, 0.2)",
              }}
            >
              <h2 className="my-2">Reset Password</h2>
              {error && <p style={{ color: "red" }}>{error}</p>}
              <form onSubmit={handleSubmit}>
                <div className="my-2">
                  <label>New Password</label>
                  <input
                    type="password"
                    value={password}
                    className="form-control"
                    onChange={(e) => setPassword(e.target.value)}
                    required
                  />
                </div>
                <div>
                  <label>Confirm New Password</label>
                  <input
                    type="password"
                    value={confirmPassword}
                    className="form-control"
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    required
                  />
                </div>
                <button type="submit" className="btn btn-dark my-3">
                  Reset Password
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ResetPassword;
