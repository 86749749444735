import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import { getApiUrl } from "../Utilities/api";
import { toast, ToastContainer } from "react-toastify";
import Cookies from "js-cookie";

const ConnectorBody = () => {
  // State for file uploads
  const [files, setFiles] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [uploadMessage, setUploadMessage] = useState("");
  const [showToast, setShowToast] = useState(false);

  // State for section navigation
  const [currentSection, setCurrentSection] = useState("upload");

  // State for document processing
  const [imageUrl, setImageUrl] = useState("");
  const [documentType, setDocumentType] = useState([]);
  const [confidence, setConfidence] = useState([]);
  const [boundingBoxes, setBoundingBoxes] = useState([]);
  const [startX, setStartX] = useState(0);
  const [startY, setStartY] = useState(0);
  const [showDocumentViewer, setShowDocumentViewer] = useState("false");
  const canvasRef = useRef(null);
  const token = localStorage.getItem("token");

  // Handle file change for uploading files

  const handleFileChangeTranscript = async (event) => {
    const selectedFiles = Array.from(event.target.files);
    setFiles(selectedFiles);
    setProgress(0); // Reset progress bar
    setUploadMessage("");
    setShowToast(false);

    if (selectedFiles.length > 0) {
      setIsLoading(true);
      let totalFiles = selectedFiles.length;
      let uploadedFiles = 0;

      for (const file of selectedFiles) {
        const formData = new FormData();
        formData.append("files", file);
        const token = Cookies.get("access_token");
        try {
          await axios.post(getApiUrl("upload/transcript"), formData, {
            headers: {
              Authorization: `Bearer ${token}`,
              "ngrok-skip-browser-warning": 1,
              "Content-Type": "multipart/form-data", // Send token in Authorization header
            },
            onUploadProgress: (event) => {
              if (event.total) {
                const percentCompleted = Math.round(
                  (event.loaded * 100) / event.total
                );
                setProgress(
                  ((uploadedFiles + percentCompleted / 100) / totalFiles) * 100
                );
              }
            },
          });
          uploadedFiles += 1;
        } catch (error) {
          if (error.response.data.detail) {
            console.error(
              "Error uploading file",
              file.name,
              error.response.data.detail
            );
            setUploadMessage(
              `Error uploading ${file.name} ` + error.response.data.detail
            );
            setShowToast(true);
            break;
          } else {
            setUploadMessage(`Error uploading file ${file.name}`);
          }
        }
      }

      if (uploadedFiles === totalFiles) {
        setUploadMessage("All files uploaded successfully!");
        setShowToast(true);
        setTimeout(() => {
          setShowToast(false);
        }, 2000);
      }
      setIsLoading(false);
    }
  };
  const handleFileChange = async (event) => {
    const selectedFiles = Array.from(event.target.files);
    selectedFiles.forEach((element) => {
      console.log(element);
      if (
        !(
          element.name.toLowerCase().includes(".png") ||
          element.name.toLowerCase().includes(".jpeg") ||
          element.name.toLowerCase().includes(".pdf") ||
          element.name.toLowerCase().includes(".jpg")
        )
      ) {
        toast.error("Please add correct file format(.png,.pdf,.jpeg,.jpg)");
        // console.log(toString(element));
        return;
      } else if (element.size >= 1048576) {
        toast.error("file too large, compress it to less than 1 MB");
        return;
      } else {
        console.log(element);
        handleFileChangeInvoice(event);
        // console.log(toString(element));
      }
    });
  };

  const handleW2 = async (event) => {
    const selectedFiles = Array.from(event.target.files);
    selectedFiles.forEach((element) => {
      console.log(element.name);
      if (
        !(
          element.name.toLowerCase().includes(".png") ||
          element.name.toLowerCase().includes(".jpeg") ||
          element.name.toLowerCase().includes(".pdf") ||
          element.name.toLowerCase().includes(".jpg")
        )
      ) {
        toast.error("Please add correct file format(.png,.pdf,.jpeg,.jpg)");
        // console.log(toString(element));
        return;
      } else if (element.size >= 1048576) {
        toast.error("file too large, compress it to less than 1 MB");
        return;
      } else {
        handleFileChangeW2(event);
        // console.log(toString(element));
      }
    });
  };
  const handleCustom = async (event) => {
    const selectedFiles = Array.from(event.target.files);
    selectedFiles.forEach((element) => {
      console.log(element.name);
      if (
        !(
          element.name.toLowerCase().includes(".png") ||
          element.name.toLowerCase().includes(".jpeg") ||
          element.name.toLowerCase().includes(".pdf") ||
          element.name.toLowerCase().includes(".jpg")
        )
      ) {
        toast.error("Please add correct file format(.png,.pdf,.jpeg,.jpg)");
        // console.log(toString(element));
        return;
      } else if (element.size >= 1048576) {
        toast.error("file too large, compress it to less than 1 MB");
        return;
      } else {
        handleFileCustom(event);
        // console.log(toString(element));
      }
    });
  };
  const handleFileCustom = async (event) => {
    const selectedFiles = Array.from(event.target.files);
    setFiles(selectedFiles);
    setProgress(0); // Reset progress bar
    setUploadMessage("");
    setShowToast(false);

    if (selectedFiles.length > 0) {
      setIsLoading(true);
      let totalFiles = selectedFiles.length;
      let uploadedFiles = 0;

      for (const file of selectedFiles) {
        const formData = new FormData();
        formData.append("files", file);
        const token = Cookies.get("access_token");
        try {
          const response = await axios.post(
            getApiUrl("upload/customclassification"),
            formData,
            {
              headers: {
                Authorization: `Bearer ${token}`,
                "ngrok-skip-browser-warning": 1,
                "Content-Type": "multipart/form-data", // Send token in Authorization header
              },
              onUploadProgress: (event) => {
                if (event.total) {
                  const percentCompleted = Math.round(
                    (event.loaded * 100) / event.total
                  );
                  setProgress(
                    ((uploadedFiles + percentCompleted / 100) / totalFiles) *
                      100
                  );
                }
              },
            }
          );
          console.log(response);
          setDocumentType(response.data.data[0].key_value_pairs[0].doc_type);
          setConfidence(response.data.data[0].key_value_pairs[0].confidence);
          setShowDocumentViewer(false);
          uploadedFiles += 1;
        } catch (error) {
          if (error.response.data.detail) {
            console.error(
              "Error uploading file",
              file.name,
              error.response.data.detail
            );
            setUploadMessage(
              `Error uploading ${file.name} ` + error.response.data.detail
            );
            setShowToast(true);
            break;
          } else {
            setUploadMessage(`Error uploading file ${file.name}`);
          }
        }
      }

      if (uploadedFiles === totalFiles) {
        setUploadMessage("All files uploaded successfully!");
        setShowToast(true);
        setTimeout(() => {
          setShowToast(false);
        }, 2000);
      }
      setIsLoading(false);
    }
  };
  const handleInsurance = async (event) => {
    const selectedFiles = Array.from(event.target.files);
    selectedFiles.forEach((element) => {
      console.log(element.name);
      if (
        !(
          element.name.toLowerCase().includes(".png") ||
          element.name.toLowerCase().includes(".jpeg") ||
          element.name.toLowerCase().includes(".pdf") ||
          element.name.toLowerCase().includes(".jpg")
        )
      ) {
        toast.error("Please add correct file format(.png,.pdf,.jpeg,.jpg)");
        // console.log(toString(element));
        return;
      } else if (element.size >= 1048576) {
        toast.error("file too large, compress it to less than 1 MB");
        return;
      } else {
        handleFileChangeInsurance(event);
        // console.log(toString(element));
      }
    });
  };
  const handleID = async (event) => {
    const selectedFiles = Array.from(event.target.files);
    selectedFiles.forEach((element) => {
      console.log(element.name);
      if (
        !(
          element.name.toLowerCase().includes(".png") ||
          element.name.toLowerCase().includes(".jpeg") ||
          element.name.toLowerCase().includes(".pdf") ||
          element.name.toLowerCase().includes(".jpg")
        )
      ) {
        toast.error("Please add correct file format(.png,.pdf,.jpeg,.jpg)");
        // console.log(toString(element));
        return;
      } else if (element.size >= 1048576) {
        toast.error("file too large, compress it to less than 1 MB");
        return;
      } else {
        handleFileChangeID(event);
        // console.log(toString(element));
      }
    });
  };
  const handleFileChangeScript = async (event) => {
    const selectedFiles = Array.from(event.target.files);
    selectedFiles.forEach((element) => {
      console.log(element.name);
      if (
        !(
          element.name.toLowerCase().includes(".png") ||
          element.name.toLowerCase().includes(".jpeg") ||
          element.name.toLowerCase().includes(".pdf") ||
          element.name.toLowerCase().includes(".jpg")
        )
      ) {
        toast.error("Please add correct file format(.png,.pdf,.jpeg,.jpg)");
        // console.log(toString(element));
        return;
      } else if (element.size >= 1048576) {
        toast.error("file too large, compress it to less than 1 MB");
        return;
      } else {
        handleFileChangeTranscript(event);
        // console.log(toString(element));
      }
    });
  };
  const handleFileChangeW2 = async (event) => {
    const selectedFiles = Array.from(event.target.files);
    setFiles(selectedFiles);
    setProgress(0); // Reset progress bar
    setUploadMessage("");
    setShowToast(false);

    if (selectedFiles.length > 0) {
      setIsLoading(true);
      let totalFiles = selectedFiles.length;
      let uploadedFiles = 0;

      for (const file of selectedFiles) {
        const formData = new FormData();
        formData.append("files", file);
        const token = Cookies.get("access_token");
        try {
          await axios.post(getApiUrl("upload/w2"), formData, {
            headers: {
              Authorization: `Bearer ${token}`,
              "ngrok-skip-browser-warning": 1,
              "Content-Type": "multipart/form-data", // Send token in Authorization header
            },
            onUploadProgress: (event) => {
              if (event.total) {
                const percentCompleted = Math.round(
                  (event.loaded * 100) / event.total
                );
                setProgress(
                  ((uploadedFiles + percentCompleted / 100) / totalFiles) * 100
                );
              }
            },
          });
          uploadedFiles += 1;
        } catch (error) {
          if (error.response.data.detail) {
            console.error(
              "Error uploading file",
              file.name,
              error.response.data.detail
            );
            setUploadMessage(
              `Error uploading ${file.name} ` + error.response.data.detail
            );
            setShowToast(true);
            break;
          } else {
            setUploadMessage(`Error uploading file ${file.name}`);
          }
        }
      }

      if (uploadedFiles === totalFiles) {
        setUploadMessage("All files uploaded successfully!");
        setShowToast(true);
        setTimeout(() => {
          setShowToast(false);
        }, 2000);
      }
      setIsLoading(false);
    }
  };
  const handleFileChangeInsurance = async (event) => {
    const selectedFiles = Array.from(event.target.files);
    setFiles(selectedFiles);
    setProgress(0); // Reset progress bar
    setUploadMessage("");
    setShowToast(false);

    if (selectedFiles.length > 0) {
      setIsLoading(true);
      let totalFiles = selectedFiles.length;
      let uploadedFiles = 0;

      for (const file of selectedFiles) {
        const formData = new FormData();
        formData.append("files", file);
        const token = Cookies.get("access_token");
        try {
          await axios.post(getApiUrl("upload/insurance"), formData, {
            headers: {
              Authorization: `Bearer ${token}`,
              "ngrok-skip-browser-warning": 1,
              "Content-Type": "multipart/form-data", // Send token in Authorization header
            },
            onUploadProgress: (event) => {
              if (event.total) {
                const percentCompleted = Math.round(
                  (event.loaded * 100) / event.total
                );
                setProgress(
                  ((uploadedFiles + percentCompleted / 100) / totalFiles) * 100
                );
              }
            },
          });
          uploadedFiles += 1;
        } catch (error) {
          if (error.response.data.detail) {
            console.error(
              "Error uploading file",
              file.name,
              error.response.data.detail
            );
            setUploadMessage(
              `Error uploading ${file.name} ` + error.response.data.detail
            );
            setShowToast(true);
            break;
          } else {
            setUploadMessage(`Error uploading file ${file.name}`);
          }
        }
      }

      if (uploadedFiles === totalFiles) {
        setUploadMessage("All files uploaded successfully!");
        setShowToast(true);
        setTimeout(() => {
          setShowToast(false);
        }, 2000);
      }
      setIsLoading(false);
    }
  };
  const handleFileChangeID = async (event) => {
    const selectedFiles = Array.from(event.target.files);
    setFiles(selectedFiles);
    setProgress(0); // Reset progress bar
    setUploadMessage("");
    setShowToast(false);

    if (selectedFiles.length > 0) {
      setIsLoading(true);
      let totalFiles = selectedFiles.length;
      let uploadedFiles = 0;

      for (const file of selectedFiles) {
        const formData = new FormData();
        formData.append("files", file);
        const token = Cookies.get("access_token");
        try {
          await axios.post(getApiUrl("upload/id"), formData, {
            headers: {
              Authorization: `Bearer ${token}`,
              "ngrok-skip-browser-warning": 1,
              "Content-Type": "multipart/form-data", // Send token in Authorization header
            },
            onUploadProgress: (event) => {
              if (event.total) {
                const percentCompleted = Math.round(
                  (event.loaded * 100) / event.total
                );
                setProgress(
                  ((uploadedFiles + percentCompleted / 100) / totalFiles) * 100
                );
              }
            },
          });
          uploadedFiles += 1;
        } catch (error) {
          if (error.response.data.detail) {
            console.error(
              "Error uploading file",
              file.name,
              error.response.data.detail
            );
            setUploadMessage(
              `Error uploading ${file.name} ` + error.response.data.detail
            );
            setShowToast(true);
            break;
          } else {
            setUploadMessage(`Error uploading file ${file.name}`);
          }
        }
      }

      if (uploadedFiles === totalFiles) {
        setUploadMessage("All files uploaded successfully!");
        setShowToast(true);
        setTimeout(() => {
          setShowToast(false);
        }, 2000);
      }
      setIsLoading(false);
    }
  };
  const handleFileChangeInvoice = async (event) => {
    const token = Cookies.get("access_token");
    const selectedFiles = Array.from(event.target.files);
    setFiles(selectedFiles);
    setProgress(0); // Reset progress bar
    setUploadMessage("");
    setShowToast(false);

    if (selectedFiles.length > 0) {
      setIsLoading(true);
      let totalFiles = selectedFiles.length;
      let uploadedFiles = 0;

      for (const file of selectedFiles) {
        const formData = new FormData();
        formData.append("files", file);
        try {
          await axios.post(getApiUrl("uploadfile/invoice"), formData, {
            headers: {
              Authorization: `Bearer ${token}`,
              "ngrok-skip-browser-warning": 1,
              "Content-Type": "multipart/form-data", // Send token in Authorization header
            },
            onUploadProgress: (event) => {
              if (event.total) {
                const percentCompleted = Math.round(
                  (event.loaded * 100) / event.total
                );
                setProgress(
                  ((uploadedFiles + percentCompleted / 100) / totalFiles) * 100
                );
              }
            },
          });
          uploadedFiles += 1;
        } catch (error) {
          if (error.response.data.detail) {
            console.error(
              "Error uploading file",
              file.name,
              error.response.data.detail
            );
            setUploadMessage(
              `Error uploading ${file.name} ` + error.response.data.detail
            );
            setShowToast(true);
            break;
          } else {
            setUploadMessage(`Error uploading file ${file.name}`);
          }
        }
      }

      if (uploadedFiles === totalFiles) {
        setUploadMessage("All files uploaded successfully!");
        setShowToast(true);
        setTimeout(() => {
          setShowToast(false);
        }, 2000);
      }
      setIsLoading(false);
    }
  };

  // Handle section navigation
  const handleUploadSection = () => {
    setCurrentSection("upload");
  };
  const handleTranslateSection = () => {
    setCurrentSection("Translate");
  };

  // Handle file change for document processing
  const handleFileChange2 = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImageUrl(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  // Handle mouse events for drawing bounding boxes
  const handleMouseDown = (e) => {
    setStartX(e.nativeEvent.offsetX);
    setStartY(e.nativeEvent.offsetY);
  };

  const handleMouseUp = (e) => {
    const endX = e.nativeEvent.offsetX;
    const endY = e.nativeEvent.offsetY;
    const box = {
      x: Math.min(startX, endX),
      y: Math.min(startY, endY),
      width: Math.abs(endX - startX),
      height: Math.abs(endY - startY),
    };
    setBoundingBoxes([...boundingBoxes, box]);
  };

  // Draw the canvas with bounding boxes
  const drawCanvas = () => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");
    const img = new Image();
    img.src = imageUrl;
    img.onload = () => {
      canvas.width = img.width;
      canvas.height = img.height;
      ctx.drawImage(img, 0, 0);

      boundingBoxes.forEach((box) => {
        ctx.beginPath();
        ctx.rect(box.x, box.y, box.width, box.height);
        ctx.strokeStyle = "red";
        ctx.lineWidth = 2;
        ctx.stroke();
      });
    };
  };

  useEffect(() => {
    if (imageUrl) {
      drawCanvas();
    }
  }, [boundingBoxes, imageUrl]);

  // Handle form submission
  const handleSubmit = async () => {
    try {
      await axios.post("/api/upload", {
        imageUrl,
        boundingBoxes,
      });
      alert("Data submitted successfully!");
    } catch (error) {
      console.error("Error submitting data:", error);
      alert("Error submitting data.");
    }
  };

  return (
    <>
      <ToastContainer />
      <div
        style={{
          position: "fixed",
          top: "50%",
          left: "50%",
          transform: "translateX(-50%) translateY(-50%)",
          zIndex: "1001",
          textAlign: "center",
          backgroundColor: "#f8f9fa",
          color: "#212529",
          border: "1px solid #ced4da",
          padding: "1rem",
          borderRadius: "0.375rem",
          boxShadow: "0px 4px 7.8px 0px rgba(0, 0, 0, 0.20)",
          height: "300px",
          width: "300px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
        hidden={showDocumentViewer}
      >
        <button
          className="btn btn-danger rounded-circle my-2"
          style={{
            position: "absolute",
            top: "0px",
            right: "7px",
            zIndex: "1000",
          }}
          onClick={() => {
            setShowDocumentViewer(true);
          }}
        >
          X
        </button>
        <p className="my-2 fs-3">
          <strong>Document type </strong>
          <p className="fs-4">{documentType}</p>
        </p>
        <p>
          <strong>Confidence = </strong>
          {confidence * 100}
        </p>
      </div>

      <div>
        {/* <p
          style={{
            display: "inline-block",
            cursor: currentSection === "upload" ? "" : "pointer",
          }}
          onClick={handleUploadSection}
          className={currentSection === "upload" ? "mx-3 fs-3" : "mx-3"}
        >
          {currentSection === "upload" ? (
            <b>
              <u>Document Extraction</u>
            </b>
          ) : (
            <p>Document Extraction</p>
          )}
        </p> */}
        {/* <p
          style={{
            display: "inline-block",
            cursor: currentSection === "Translate" ? "" : "pointer",
          }}
          onClick={handleTranslateSection}
          className={currentSection === "Translate" ? "mx-3 fs-3" : "mx-3"}
        >
          {currentSection === "Translate" ? (
            <b>
              <u>Translate Documents</u>
            </b>
          ) : (
            <p>Translate Documents</p>
          )}
        </p> */}
      </div>
      {currentSection === "upload" ? (
        <div
          style={{
            maxWidth: "100%",
            position: "relative",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          {isLoading && (
            <div
              style={{
                position: "fixed",
                top: "0",
                left: "0",
                width: "100%",
                zIndex: "1000",
                backgroundColor: "#28a745",
                height: "5px",
                transition: "width 0.4s ease-in-out",
                width: `${progress}%`,
              }}
            />
          )}
          {showToast && (
            <div
              style={{
                position: "fixed",
                top: "5%",
                left: "50%",
                transform: "translateX(-50%)",
                zIndex: "1001",
                textAlign: "center",
                backgroundColor: "#f8f9fa",
                color: "#212529",
                border: "1px solid #ced4da",
                padding: "1rem",
                borderRadius: "0.375rem",
              }}
            >
              <span>{uploadMessage}</span>
            </div>
          )}
          <div style={{ textAlign: "center", marginBottom: "2rem" }}>
            {/* <h1 style={{ fontSize: "2.5rem", fontWeight: "bold" }}>
              Upload your files for data extraction
            </h1> */}
            <p style={{ color: "#039AC2", margin: "0" }} className="fs-4">
              <b>Pick a model that matches your document type.</b>
            </p>
          </div>
          <div
            style={{
              width: "100%",
              overflow: "auto",
              display: "grid",
              gridTemplateColumns: "1",
              gridTemplateColumns: "2fr 2fr 1fr",
            }}
          >
            {/* <div
              style={{
                height: "2vh",
                width: "100%",
                position: "relative",
                cursor: "pointer",
                padding: "15px",
                background: "linear-gradient(90deg, #e0eafc, #cfdef3)", // Light gradient background
                borderRadius: "10px", // Optional: To make it look smoother
                boxShadow: "0 8px 10px rgba(0, 0, 0, 0.3)", // Optional: To add some depth
                padding: "5%",
              }}
              className="d-flex justify-content-center align-items-center flex-column my-3"
            >
              <p>
                <svg
                  width="64"
                  height="64"
                  viewBox="0 0 1024 1024"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="#0F1F3C"
                >
                  <path d="M914.29 219.43c0-80.66-65.62-146.29-146.29-146.29H274.29c-84.57 0-155.45 64.11-164.57 146.29v475.43h146.29v256h585.14V346.08c43.69-25.34 73.14-72.62 73.14-126.65z m-585.14 73.14c0-19.55 15.43-35.57 34.75-36.53a90.403 90.403 0 0 1-16.44 36.53h-18.31zM182.87 621.71v-384c0-50.41 41.02-91.43 91.43-91.43 30.46 0 57.48 14.97 74.1 37.94-52.3 8.33-92.39 53.74-92.39 108.34v329.15h-73.14z m585.14 256H329.15V365.72h438.86v511.99z m0-585.14H429.54c6.12-17.37 9.32-35.86 9.32-54.86 0-33.81-10.25-65.26-27.8-91.43H768c40.34 0 73.14 32.8 73.14 73.14s-32.79 73.15-73.13 73.15z" />
                  <path d="M416.392 762.75L635.83 449.3l44.933 31.456-219.438 313.45zM457.15 588.27c42.34 0 76.8-34.46 76.8-76.8s-34.46-76.8-76.8-76.8c-42.34 0-76.8 34.46-76.8 76.8s34.46 76.8 76.8 76.8z m0-109.72c18.14 0 32.91 14.77 32.91 32.91 0 18.14-14.77 32.91-32.91 32.91-18.14 0-32.91-14.77-32.91-32.91 0-18.14 14.77-32.91 32.91-32.91zM563.2 731.46c0 42.34 34.46 76.8 76.8 76.8s76.8-34.46 76.8-76.8-34.46-76.8-76.8-76.8-76.8 34.46-76.8 76.8z m109.72 0c0 18.14-14.77 32.91-32.91 32.91s-32.91-14.77-32.91-32.91c0-18.14 14.77-32.91 32.91-32.91s32.91 14.77 32.91 32.91z" />
                </svg>
              </p>
              <p
                className="fs-6 text-center"
                style={{ height: "fit-content", margin: "0" }}
              >
                <b>Invoice Model</b>
              </p>
              <form>
                <input
                  type="file"
                  multiple
                  className="input-field"
                  onChange={handleFileChange}
                  style={{
                    height: "100%",
                    width: "100%",
                    position: "absolute",
                    top: "0",
                    left: "0",
                    cursor: "pointer",
                    opacity: "0",
                  }}
                />
              </form>
            </div> */}
            <div
              className="card text-center mx-2 my-2"
              style={{
                width: "20rem",
                height: "32rem",
                padding: "5%",
                background: "#039AC220",
                boxShadow: "0px 4px 7.8px 0px rgba(0, 0, 0, 0.20)",
              }}
            >
              <img
                src="https://doc-forensics-assests.s3.ap-south-1.amazonaws.com/4023504+1+(1).svg"
                className="card-img-top"
                alt="..."
              />
              <div
                className="card-body"
                style={{
                  position: "relative",
                  marginLeft: "0",
                  marginBottom: "0",
                  marginRight: "0",
                  padding: "0",
                  marginTop: "15px",
                }}
              >
                <h5 className="card-title">Invoice Model</h5>
                <p className="card-text">
                  Extract invoice ID, customer details, vendor details, ship to,
                  bill to, total tax, subtotal, line items and more.
                </p>
                <label
                  style={{
                    position: "relative",
                    cursor: "pointer",
                    background: "#039AC2",
                    color: "white",
                    padding: "0.5rem 1rem",
                    display: "inline-block",
                  }}
                  className="btn btn-primary"
                >
                  Upload Invoice
                  <input
                    type="file"
                    multiple
                    className="input-field"
                    onChange={handleFileChange}
                    style={{
                      display: "none", // Hide the input but keep its functionality
                    }}
                  />
                </label>
              </div>
            </div>

            <div
              className="card text-center my-2 mx-2"
              style={{
                width: "20rem",
                height: "32rem",
                padding: "5%",
                background: "#039AC220",
                boxShadow: "0px 4px 7.8px 0px rgba(0, 0, 0, 0.20)",
              }}
            >
              <img
                src="https://doc-forensics-assests.s3.ap-south-1.amazonaws.com/20945424+1.svg"
                className="card-img-top"
                alt="..."
              />
              <div
                className="card-body"
                style={{
                  position: "relative",
                  marginLeft: "0",
                  marginBottom: "0",
                  marginRight: "0",
                  padding: "0",
                  marginTop: "15px",
                }}
              >
                <h5 className="card-title">Transcript Model</h5>
                <p className="card-text">
                  Extract student ID, student name, course details, subject
                  names, grades, total marks and additional remarks.
                </p>
                <label
                  style={{
                    position: "relative",
                    cursor: "pointer",
                    background: "#039AC2",
                    color: "white",
                    padding: "0.5rem 1rem",
                    display: "inline-block",
                  }}
                  className="btn btn-primary"
                >
                  Upload Transcript
                  <input
                    type="file"
                    multiple
                    className="input-field"
                    onChange={handleFileChangeScript}
                    style={{
                      display: "none", // Hide the input but keep its functionality
                    }}
                  />
                </label>
              </div>
            </div>
            <div
              className="card text-center mx-2 my-2"
              style={{
                width: "20rem",
                height: "32rem",
                padding: "5%",
                background: "#039AC220",
                boxShadow: "0px 4px 7.8px 0px rgba(0, 0, 0, 0.20)",
              }}
            >
              <img
                src="https://doc-forensics-assests.s3.ap-south-1.amazonaws.com/4417865+1.svg"
                className="card-img-top"
                alt="..."
              />
              <div
                className="card-body"
                style={{
                  position: "relative",
                  marginLeft: "0",
                  marginBottom: "0",
                  marginRight: "0",
                  padding: "0",
                  marginTop: "15px",
                }}
              >
                <h5 className="card-title">Identity Model</h5>
                <p className="card-text">
                  Extract name, expiration date, machine readable zone, and more
                  from passports and ID cards.
                </p>
                <label
                  style={{
                    position: "relative",
                    cursor: "pointer",
                    background: "#039AC2",
                    color: "white",
                    padding: "0.5rem 1rem",
                    display: "inline-block",
                  }}
                  className="btn btn-primary"
                >
                  Upload ID
                  <input
                    type="file"
                    multiple
                    className="input-field"
                    onChange={handleID}
                    style={{
                      display: "none", // Hide the input but keep its functionality
                    }}
                  />
                </label>
              </div>
            </div>
            <div
              className="card text-center mx-2 my-2"
              style={{
                width: "20rem",
                height: "32rem",
                padding: "5%",
                background: "#039AC220",
                boxShadow: "0px 4px 7.8px 0px rgba(0, 0, 0, 0.20)",
              }}
            >
              <img
                src="https://doc-forensics-assests.s3.ap-south-1.amazonaws.com/OF71YE0+1.png"
                className="card-img-top"
                alt="..."
              />
              <div
                className="card-body"
                style={{
                  position: "relative",
                  marginLeft: "0",
                  marginBottom: "0",
                  marginRight: "0",
                  padding: "0",
                  marginTop: "15px",
                }}
              >
                <h5 className="card-title">W2 Model</h5>
                <p className="card-text">
                  Extract name, Social Security Number (SSN), income details,
                  employer information, and more from W2 forms.
                </p>
                <label
                  style={{
                    position: "relative",
                    cursor: "pointer",
                    background: "#039AC2",
                    color: "white",
                    padding: "0.5rem 1rem",
                    display: "inline-block",
                  }}
                  className="btn btn-primary"
                >
                  Upload W2
                  <input
                    type="file"
                    multiple
                    className="input-field"
                    onChange={handleW2}
                    style={{
                      display: "none", // Hide the input but keep its functionality
                    }}
                  />
                </label>
              </div>
            </div>
            {/* <div
              className="card text-center mx-2 my-2"
              style={{
                width: "20rem",
                height: "32rem",
                padding: "5%",
                background: "#039AC220",
                boxShadow: "0px 4px 7.8px 0px rgba(0, 0, 0, 0.20)",
              }}
            >
              <img
                src="https://doc-forensics-assests.s3.ap-south-1.amazonaws.com/10003274+1.svg"
                className="card-img-top"
                alt="..."
              />
              <div
                className="card-body"
                style={{
                  position: "relative",
                  marginLeft: "0",
                  marginBottom: "0",
                  marginRight: "0",
                  padding: "0",
                  marginTop: "15px",
                }}
              >
                <h5 className="card-title">Insurance Model</h5>
                <p className="card-text">
                  Extract policyholder name, policy number, coverage details,
                  premium amounts, and more.
                </p>
                <label
                  style={{
                    position: "relative",
                    cursor: "pointer",
                    background: "#039AC2",
                    color: "white",
                    padding: "0.5rem 1rem",
                    display: "inline-block",
                  }}
                  className="btn btn-primary"
                >
                  Upload Insurance
                  <input
                    type="file"
                    multiple
                    className="input-field"
                    onChange={handleInsurance}
                    style={{
                      display: "none", // Hide the input but keep its functionality
                    }}
                  />
                </label>
              </div>
            </div>
            <div
              className="card text-center mx-2 my-2"
              style={{
                width: "20rem",
                height: "32rem",
                padding: "5%",
                background: "#039AC220",
                boxShadow: "0px 4px 7.8px 0px rgba(0, 0, 0, 0.20)",
              }}
            >
              <img
                src="https://doc-forensics-assests.s3.ap-south-1.amazonaws.com/6861488+1.svg"
                className="card-img-top"
                alt="..."
              />
              <div
                className="card-body"
                style={{
                  position: "relative",
                  marginLeft: "0",
                  marginBottom: "0",
                  marginRight: "0",
                  padding: "0",
                  marginTop: "15px",
                }}
              >
                <h5 className="card-title">Custom Model</h5>
                <p className="card-text">
                  Make your custom document extractor to extract different type
                  of documents using our custom model.
                </p>
                <label
                  style={{
                    position: "relative",
                    cursor: "pointer",
                    background: "#039AC2",
                    color: "white",
                    padding: "0.5rem 1rem",
                    display: "inline-block",
                  }}
                  className="btn btn-primary"
                >
                  Upload Insurance
                  <input
                    type="file"
                    multiple
                    className="input-field"
                    // onChange={handleInsurance}
                    onChange={handleCustom}
                    style={{
                      display: "none", // Hide the input but keep its functionality
                    }}
                  />
                </label>
              </div>
            </div> */}
            {/* <div
              className="card text-center mx-2 my-3"
              style={{ width: "18rem" }}
            >
              <img
                src="https://documentintelligence.ai.azure.com/images/home/home-custommodel-card.svg"
                className="card-img-top"
                alt="..."
              />
              <div className="card-body" style={{ position: "relative" }}>
                <h5 className="card-title">Custom Model</h5>
                <p className="card-text">
                  Label and build a custom model to extract a specific schema
                  from your forms and documents.
                </p>
                <button
                  style={{
                    position: "relative",
                    cursor: "pointer",
                    background: "#039AC2",
                    color: "white",
                  }}
                  className="btn btn-primary"
                >
                  Create Custom
                </button>
              </div>
            </div> */}
            {/* <div
              style={{
                height: "2vh",
                width: "100%",
                position: "relative",
                cursor: "pointer",
                padding: "15px",
                background: "linear-gradient(90deg, #ffe5e0, #ffecd2)", // Light pink to peach gradient
                borderRadius: "10px", // Smooth the edges
                boxShadow: "0 8px 6px rgba(0, 0, 0, 0.3)", // Adds some depth
                padding: "5%",
              }}
              className="d-flex justify-content-center align-items-center flex-column mx-2"
            >
              <p>
                <svg
                  fill="#0F1F3C"
                  height="40px"
                  width="40px"
                  version="1.1"
                  id="Capa_1"
                  viewBox="0 0 349.255 349.255"
                >
                  <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                  <g
                    id="SVGRepo_tracerCarrier"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></g>
                  <g id="SVGRepo_iconCarrier">
                    {" "}
                    <path d="M278.509,349.255c-0.162,0-0.324-0.005-0.487-0.015l-226.03-13.787c-4.41-0.269-7.767-4.062-7.498-8.473 s4.055-7.754,8.473-7.498l217.543,13.269v-5.487c0-4.418,3.582-8,8-8s8,3.582,8,8v13.99c0,2.206-0.911,4.314-2.518,5.827 C282.503,348.482,280.54,349.255,278.509,349.255z M296.777,309.159H52.478c-4.418,0-8-3.582-8-8V8c0-4.418,3.582-8,8-8h244.299 c4.418,0,8,3.582,8,8v293.159C304.777,305.578,301.195,309.159,296.777,309.159z M60.478,293.159h228.299V16H60.478V293.159z M186.718,261.63h-24.183c-4.418,0-8-3.582-8-8s3.582-8,8-8h24.183c4.418,0,8,3.582,8,8S191.136,261.63,186.718,261.63z M147.375,194.277c-1.497,0-2.987-0.419-4.29-1.247c-2.311-1.468-3.711-4.016-3.711-6.753v-56.114 c-7.776-8.502-12.526-19.816-12.526-32.219c0-26.346,21.434-47.78,47.779-47.78s47.78,21.434,47.78,47.78 c0,26.346-21.435,47.78-47.78,47.78c-6.846,0-13.36-1.448-19.253-4.052v32.007l15.021-7.056c2.097-0.984,4.516-1.013,6.636-0.077 l16.851,7.448v-17.397c0-4.418,3.582-8,8-8s8,3.582,8,8v29.679c0,2.705-1.367,5.227-3.634,6.704 c-2.267,1.476-5.124,1.707-7.601,0.614l-24.749-10.939l-23.122,10.862C149.694,194.025,148.532,194.277,147.375,194.277z M174.627,66.165c-17.523,0-31.779,14.256-31.779,31.78c0,17.524,14.256,31.78,31.779,31.78s31.78-14.256,31.78-31.78 C206.407,80.421,192.15,66.165,174.627,66.165z"></path>{" "}
                  </g>
                </svg>
              </p>
              <p className="fs-6 text-center">
                <b>Transcript Model</b>
              </p>
              <form>
                <input
                  type="file"
                  multiple
                  className="input-field"
                  onChange={handleFileChangeScript}
                  style={{
                    height: "100%",
                    width: "100%",
                    position: "absolute",
                    top: "0",
                    left: "0",
                    cursor: "pointer",
                    opacity: "0",
                  }}
                />
              </form>
            </div> */}
            {/* <div
              style={{
                height: "2vh",
                width: "100%",
                position: "relative",
                cursor: "pointer",
                padding: "15px",
                background: "linear-gradient(90deg, #d4fc79, #96e6a1)", // Light pink to peach gradient
                borderRadius: "10px", // Smooth the edges
                boxShadow: "0 8px 6px rgba(0, 0, 0, 0.3)", // Adds some depth
                padding: "5%",
              }}
              className="d-flex justify-content-center align-items-center flex-column my-3"
            >
              <p className="fs-6 text-center">
                <br />
                <b>Identity Document Model</b>
              </p>
              <form>
                <input
                  type="file"
                  multiple
                  className="input-field"
                  onChange={handleID}
                  style={{
                    height: "100%",
                    width: "100%",
                    position: "absolute",
                    top: "0",
                    left: "0",
                    cursor: "pointer",
                    opacity: "0",
                  }}
                />
              </form>
            </div> */}
          </div>
        </div>
      ) : (
        <>
          <div
            style={{
              margin: "2rem auto",
              maxWidth: "800px",
              position: "relative",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            {isLoading && (
              <div
                style={{
                  position: "fixed",
                  top: "0",
                  left: "0",
                  width: "100%",
                  zIndex: "1000",
                  backgroundColor: "#28a745",
                  height: "5px",
                  transition: "width 0.4s ease-in-out",
                  width: `${progress}%`,
                }}
              />
            )}
            {showToast && (
              <div
                style={{
                  position: "fixed",
                  top: "5%",
                  left: "50%",
                  transform: "translateX(-50%)",
                  zIndex: "1001",
                  textAlign: "center",
                  backgroundColor: "#f8f9fa",
                  color: "#212529",
                  border: "1px solid #ced4da",
                  padding: "1rem",
                  borderRadius: "0.375rem",
                }}
              >
                <span>{uploadMessage}</span>
              </div>
            )}
            <div style={{ textAlign: "center", marginBottom: "2rem" }}>
              <h1 style={{ fontSize: "2.5rem", fontWeight: "bold" }}>
                Upload your files here to translate
              </h1>
            </div>
            <div
              style={{
                height: "fit-content",
                width: "fit-content",
                position: "relative",
                cursor: "pointer",
                padding: "15px",
                backgroundColor: "#f0f0f0",
                padding: "10%",
              }}
              className="d-flex justify-content-center align-items-center flex-column mx-2"
            >
              <p className="fs-6 text-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  x="0px"
                  y="0px"
                  width="80"
                  height="80"
                  viewBox="0 0 64 64"
                >
                  <path
                    fill="#acb7d0"
                    d="M7,51V13c0-3.31,2.69-6,6-6h38c3.31,0,6,2.69,6,6v38c0,3.31-2.69,6-6,6H13C9.69,57,7,54.31,7,51z"
                  ></path>
                  <path
                    fill="#8d6c9f"
                    d="M51,58H13c-3.859,0-7-3.14-7-7V13c0-3.86,3.141-7,7-7h38c3.859,0,7,3.14,7,7v38 C58,54.86,54.859,58,51,58z M13,8c-2.757,0-5,2.243-5,5v38c0,2.757,2.243,5,5,5h38c2.757,0,5-2.243,5-5V13c0-2.757-2.243-5-5-5H13z"
                  ></path>
                  <path
                    fill="#ace3ff"
                    d="M32,51c-10.477,0-19-8.523-19-19s8.523-19,19-19s19,8.523,19,19S42.477,51,32,51z M32,15 c-9.374,0-17,7.626-17,17s7.626,17,17,17s17-7.626,17-17S41.374,15,32,15z"
                  ></path>
                  <path
                    fill="#ace3ff"
                    d="M44.818,44.802C41.575,42.386,36.902,41,32,41s-9.575,1.386-12.818,3.802l-1.195-1.604 C21.568,40.53,26.676,39,32,39s10.432,1.53,14.014,4.197L44.818,44.802z"
                  ></path>
                  <path
                    fill="#ace3ff"
                    d="M32,25c-5.324,0-10.432-1.53-14.014-4.197l1.195-1.604C22.425,21.614,27.098,23,32,23 s9.575-1.386,12.818-3.802l1.195,1.604C42.432,23.47,37.324,25,32,25z"
                  ></path>
                  <path
                    fill="#ace3ff"
                    d="M32,51c-5.607,0-10-8.346-10-19s4.393-19,10-19s10,8.346,10,19S37.607,51,32,51z M32,15 c-4.337,0-8,7.785-8,17s3.663,17,8,17s8-7.785,8-17S36.337,15,32,15z"
                  ></path>
                  <rect
                    width="36"
                    height="2"
                    x="14"
                    y="31"
                    fill="#ace3ff"
                  ></rect>
                  <rect
                    width="2"
                    height="36"
                    x="31"
                    y="14"
                    fill="#ace3ff"
                  ></rect>
                  <path
                    fill="#fff8ee"
                    d="M14,28L14,28v3.069c0,0.713,0.862,1.07,1.366,0.566L19,28h6c1.657,0,3-1.343,3-3v-7 c0-1.657-1.343-3-3-3H14c-1.657,0-3,1.343-3,3v7C11,26.657,12.343,28,14,28z"
                  ></path>
                  <path
                    fill="#8d6c9f"
                    d="M14.802,32.87c-0.232,0-0.467-0.045-0.69-0.138C13.437,32.452,13,31.799,13,31.069v-2.195 c-1.724-0.445-3-2.013-3-3.874v-7c0-2.206,1.794-4,4-4h11c2.206,0,4,1.794,4,4v7c0,2.206-1.794,4-4,4h-5.586l-3.342,3.341 C15.728,32.687,15.269,32.87,14.802,32.87z M14,16c-1.103,0-2,0.897-2,2v7c0,1.103,0.897,2,2,2c0.553,0,1,0.448,1,1v2.585 l3.293-3.292C18.48,27.105,18.734,27,19,27h6c1.103,0,2-0.897,2-2v-7c0-1.103-0.897-2-2-2H14z"
                  ></path>
                  <path
                    fill="#7bbeeb"
                    d="M50,49L50,49v3.069c0,0.713-0.862,1.07-1.366,0.566L45,49h-6c-1.657,0-3-1.343-3-3v-7 c0-1.657,1.343-3,3-3h11c1.657,0,3,1.343,3,3v7C53,47.657,51.657,49,50,49z"
                  ></path>
                  <path
                    fill="#8d6c9f"
                    d="M49.198,53.87c-0.467,0-0.926-0.183-1.271-0.528L44.586,50H39c-2.206,0-4-1.794-4-4v-7 c0-2.206,1.794-4,4-4h11c2.206,0,4,1.794,4,4v7c0,1.86-1.276,3.428-3,3.874v2.195c0,0.73-0.437,1.383-1.111,1.663 C49.665,53.824,49.431,53.87,49.198,53.87z M39,37c-1.103,0-2,0.897-2,2v7c0,1.103,0.897,2,2,2h6c0.266,0,0.52,0.105,0.707,0.293 L49,51.585V49c0-0.552,0.447-1,1-1c1.103,0,2-0.897,2-2v-7c0-1.103-0.897-2-2-2H39z"
                  ></path>
                  <path
                    fill="#8d6c9f"
                    d="M42.087,13.468c-0.154,0-0.312-0.036-0.458-0.112c-0.491-0.253-0.683-0.857-0.43-1.348l0.919-1.777 c0.253-0.49,0.857-0.684,1.348-0.429c0.491,0.253,0.683,0.857,0.43,1.348l-0.919,1.777C42.799,13.271,42.449,13.468,42.087,13.468z"
                  ></path>
                  <path
                    fill="#8d6c9f"
                    d="M45.884,15.949c-0.222,0-0.445-0.074-0.631-0.225c-0.428-0.349-0.492-0.979-0.144-1.407l1.264-1.549 c0.35-0.429,0.979-0.493,1.407-0.143c0.428,0.349,0.492,0.979,0.144,1.407l-1.264,1.549C46.462,15.823,46.174,15.949,45.884,15.949 z"
                  ></path>
                  <path
                    fill="#8d6c9f"
                    d="M49.099,19.155c-0.292,0-0.58-0.126-0.778-0.371c-0.348-0.429-0.281-1.059,0.148-1.407l1.554-1.258 c0.429-0.349,1.059-0.281,1.406,0.148c0.348,0.429,0.281,1.059-0.148,1.407l-1.554,1.258 C49.542,19.082,49.319,19.155,49.099,19.155z"
                  ></path>
                  <path
                    fill="#8d6c9f"
                    d="M51.566,22.964c-0.363,0-0.713-0.198-0.891-0.542c-0.252-0.491-0.059-1.094,0.433-1.347l1.778-0.915 c0.493-0.254,1.094-0.059,1.347,0.432c0.252,0.491,0.059,1.094-0.433,1.347l-1.778,0.915C51.876,22.929,51.72,22.964,51.566,22.964 z"
                  ></path>
                  <path
                    fill="#8d6c9f"
                    d="M20.992,54.309c-0.154,0-0.312-0.036-0.458-0.112c-0.491-0.253-0.683-0.857-0.43-1.348l0.919-1.777 c0.254-0.491,0.857-0.684,1.348-0.429c0.491,0.253,0.683,0.857,0.43,1.348l-0.919,1.777C21.704,54.112,21.354,54.309,20.992,54.309 z"
                  ></path>
                  <path
                    fill="#8d6c9f"
                    d="M16.851,51.601c-0.222,0-0.445-0.074-0.631-0.225c-0.428-0.349-0.492-0.979-0.144-1.407l1.264-1.549 c0.35-0.428,0.979-0.493,1.407-0.143c0.428,0.349,0.492,0.979,0.144,1.407l-1.264,1.549C17.429,51.475,17.141,51.601,16.851,51.601 z"
                  ></path>
                  <path
                    fill="#8d6c9f"
                    d="M13.35,48.104c-0.292,0-0.58-0.126-0.778-0.371c-0.348-0.429-0.281-1.059,0.148-1.407l1.554-1.258 c0.429-0.348,1.058-0.281,1.406,0.148c0.348,0.429,0.281,1.059-0.148,1.407l-1.554,1.258C13.793,48.031,13.57,48.104,13.35,48.104z"
                  ></path>
                  <path
                    fill="#8d6c9f"
                    d="M10.657,43.95c-0.363,0-0.713-0.198-0.891-0.542c-0.252-0.491-0.059-1.094,0.433-1.347l1.778-0.915 c0.492-0.254,1.093-0.059,1.347,0.432c0.252,0.491,0.059,1.094-0.433,1.347l-1.778,0.915C10.967,43.915,10.811,43.95,10.657,43.95z"
                  ></path>
                </svg>
                <br />
                Upload from local
              </p>
              <form>
                <input
                  type="file"
                  multiple
                  className="input-field"
                  // onChange={handleFileChange}
                  style={{
                    height: "100%",
                    width: "100%",
                    position: "absolute",
                    top: "0",
                    left: "0",
                    cursor: "pointer",
                    opacity: "0",
                  }}
                />
              </form>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default ConnectorBody;
