import axios from "axios";
import React, { useEffect, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { getApiUrl } from "../Utilities/api";

export default function Success({ auth }) {
  const navigate = useNavigate();
  useEffect(() => {
    if (!auth) {
      navigate("/");
    }
  });
  const mail = localStorage.getItem("mail");
  console.log(mail);
  const [otp, setOtp] = useState("");
  const resendOtp = async () => {
    try {
      setloading(true);
      const queryParams = `email=${mail}`;
      const response = await axios.post(
        getApiUrl("auth/resend-otp?" + queryParams)
      );
      console.log(response);
      setloading(false);
      setAlertBody("Success");
      setAlert(true);
      setTimeout(() => {
        setAlert(false);
      }, 2000);
    } catch (error) {
      const alert = document.body.getElementsByClassName("alert")[0];
      alert.setAttribute("class", "alert alert-danger alert");
      setloading(false);
      setAlertBody(error.response.data.detail);
      setAlert(true);
      setTimeout(() => {
        setAlert(false);
        alert.setAttribute("class", "alert alert-primary alert");
      }, 2000);
      console.log(error);
    }
  };
  const verifyOtp = async () => {
    try {
      setloading(true);
      const queryParams = `email=${mail}&otp=${otp}`;
      const response = await axios.post(
        getApiUrl("auth/verify-otp?" + queryParams)
      );
      console.log(response);
      setloading(false);
      setAlertBody(response.data.detail);
      setAlert(true);
      setTimeout(() => {
        setAlert(false);
      }, 2000);
    } catch (error) {
      const alert = document.body.getElementsByClassName("alert")[0];
      alert.setAttribute("class", "alert alert-danger alert");
      setloading(false);
      setAlertBody(error.response.data.detail);
      setAlert(true);
      setTimeout(() => {
        setAlert(false);
        alert.setAttribute("class", "alert alert-primary alert");
      }, 2000);
      console.log(error);
    }
  };

  const [loading, setloading] = useState(false);
  const [alert, setAlert] = useState(false);
  const [alertBody, setAlertBody] = useState("");
  const handleOtp = (e) => {
    setOtp(e.target.value);
    console.log(otp);
  };
  const backToLogin = () => {
    navigate("/");
  };

  return (
    <>
      <p
        style={{
          position: "absolute",
          top: "12px",
          left: "24px",
          cursor: "pointer",
          zIndex: "2000",
        }}
        className="fs-4"
        onClick={backToLogin}
      >
        <u>&lt; Login Page</u>
      </p>
      <div
        style={{
          position: "absolute",
          height: "100vh",
          width: "100vw",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          className="fs-3 loader"
          style={{ position: "absolute", zIndex: "1000" }}
          hidden={!loading}
        />
        <div
          className="alert alert-primary alert"
          role="alert"
          style={{ position: "absolute", top: "0", zIndex: "1000" }}
          hidden={!alert}
        >
          {alertBody}
        </div>
      </div>
      <div
        style={{
          height: "100vh",
          width: "100vw",
          opacity: "0.7",
          background: "white",
          position: "absolute",
          zIndex: "888",
        }}
        hidden={!loading}
      ></div>
      <div
        style={{ height: "100vh" }}
        className="d-flex align-items-center justify-content-center"
      >
        <div className="col-md-3">
          <div className="card">
            <div
              className="card-body d-flex justify-content-center flex-column align-items-center px-2"
              style={{
                padding: "0",
                borderRadius: "6px",
                boxShadow: "20px 20px 20px rgba(0, 0, 0, 0.2)",
                border: "1px solid rgba(0, 0, 0, 0.2)",
              }}
            >
              <h3 className="my-3">
                <b>Success</b>
              </h3>
              <div
                className="form-group input-container"
                style={{ position: "relative", padding: "5px" }}
              >
                <p className="fs-4 text-center">
                  Check your mail,verify your email then login
                </p>
              </div>
              <input
                className="form-control my-2 text-center"
                onChange={handleOtp}
                value={otp}
              />
              <p
                style={{ color: "#039AC2", cursor: "pointer" }}
                onClick={resendOtp}
              >
                Resend otp?
              </p>
              <button
                className="btn btn-dark"
                style={{ marginBottom: "10px" }}
                onClick={verifyOtp}
              >
                Verify
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
