import React, { useState, useEffect } from "react";
import myImage from "../Utilities/Media/account.png";
import Cookies from "js-cookie";
import axios from "axios";
import { getApiUrl } from "../Utilities/api";

function Navbar({ setMobile, mobile, setClicked }) {
  const [credit, setCredit] = useState("loading...");
  const dotStyle = {
    display: "inline-block",
    margin: "0 2px",
  };

  const handleClick = () => {
    setClicked(true);
  };
  const userName = async () => {
    const token = Cookies.get("access_token");
    try {
      const response = await axios.get(getApiUrl("users/me"), {
        headers: {
          Authorization: `Bearer ${token}`,
          "ngrok-skip-browser-warning": 1, // Send token in Authorization header
        },
      });
      setCredit(response.data.data.name);
      console.log(response);
      // localStorage.setItem("name", response.data.name);
    } catch (error) {
      // if (error.response.status === 401) {
      //   toast.info("session expired login again");
      // } else {
      //   toast.error("session expired login again!");
      // }
      console.log(error);
    }
  };
  useEffect(() => {
    userName();
  }, []);
  return (
    <nav
      className="navbar navbar-expand-lg"
      style={{
        background: "#FFFFFF",
        width: "100%",
        height: "12vh",
        // border: "1px solid #E7E7E7",
        position: "relative",
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      <div
        className="dots mx-3"
        style={{
          height: "40px",
          width: "30px",
          cursor: "pointer",
          display: mobile ? "block" : "none",
        }}
        onClick={handleClick}
      >
        <svg
          fill="#FF8225"
          version="1.1"
          id="Capa_1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24.75 24.75"
          stroke="#FF8225"
        >
          <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
          <g
            id="SVGRepo_tracerCarrier"
            strokeLinecap="round"
            strokeLinejoin="round"
          ></g>
          <g id="SVGRepo_iconCarrier">
            <g>
              <path d="M0,3.875c0-1.104,0.896-2,2-2h20.75c1.104,0,2,0.896,2,2s-0.896,2-2,2H2C0.896,5.875,0,4.979,0,3.875z M22.75,10.375H2 c-1.104,0-2,0.896-2,2c0,1.104,0.896,2,2,2h20.75c1.104,0,2-0.896,2-2C24.75,11.271,23.855,10.375,22.75,10.375z M22.75,18.875H2 c-1.104,0-2,0.896-2,2s0.896,2,2,2h20.75c1.104,0,2-0.896,2-2S23.855,18.875,22.75,18.875z"></path>
            </g>
          </g>
        </svg>
      </div>
      <img
        src="https://doc-forensics-assests.s3.ap-south-1.amazonaws.com/account.png"
        style={{ marginLeft: "auto", marginRight: "10px" }}
      ></img>
    </nav>
  );
}

export default Navbar;
