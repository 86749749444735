import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import axios from "axios";
import { getApiUrl } from "./Utilities/api";
import Cookies from "js-cookie";
import { Navigate } from "react-router-dom";

// Create Axios instance

// Intercept the response
let isRefreshing = false; // To track the status of refreshing token
let failedQueue = []; // Queue to store failed requests while the token is being refreshed

const processQueue = (error, token = null) => {
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });
  failedQueue = [];
};

axios.interceptors.response.use(
  (response) => {
    // If the response is successful, return it
    return response;
  },
  async (error) => {
    console.log(error);
    const originalRequest = error.config;
    // alert(originalRequest.url);
    const excludedUrls = [
      "auth/login",
      "auth/register",
      "auth/forgot-password",
      "auth/reset-password",
      "auth/verify-otp",
      "auth/resend-otp",
      "auth/logout",
      "auth/refresh-token",
    ];
    console.log(excludedUrls[0]); // Add the API endpoints you want to exclude
    // alert(excludedUrls[0]);
    if (excludedUrls.some((url) => originalRequest.url.includes(url))) {
      return Promise.reject(error); // Skip interceptor logic for these APIs
    }

    // If the request is to refresh the token and it failed (401), break the loop
    if (originalRequest.url.includes("auth/refresh-token")) {
      return Promise.reject(error); // Avoid infinite loop
    }

    // If the error is due to an expired token (401 Unauthorized)
    if (
      error.response &&
      error.response.status === 401 &&
      !originalRequest._retry
    ) {
      originalRequest._retry = true; // Mark the request as retried

      if (!isRefreshing) {
        isRefreshing = true;

        const refresh_token = Cookies.get("refresh_token");

        try {
          // Refresh the token
          const refreshTokenResponse = await axios.post(
            getApiUrl("auth/refresh-token"),
            {},
            {
              headers: {
                "refresh-token": refresh_token, // Send refresh token in headers
              },
            }
          );
          const newToken = refreshTokenResponse.data.access_token;
          const newRefreshToken = refreshTokenResponse.data.refresh_token;

          // Store the new token (depending on your implementation)
          Cookies.set("access_token", newToken, { expires: 31 });
          Cookies.set("refresh_token", newRefreshToken, { expires: 31 });

          // Update the failed request's token
          originalRequest.headers["Authorization"] = `Bearer ${newToken}`;

          processQueue(null, newToken); // Retry all the requests in the queue

          return axios(originalRequest); // Retry the original request
        } catch (refreshError) {
          processQueue(refreshError, null); // If refresh fails, reject all the queued requests
          window.location.href = "/"; // Redirect to login if refresh fails
          return Promise.reject(refreshError);
        } finally {
          isRefreshing = false; // Reset the refresh status
        }
      }

      // Add the request to the queue to retry once the token is refreshed
      return new Promise((resolve, reject) => {
        failedQueue.push({ resolve, reject });
      });
    }

    // If the error is not related to token expiration, reject the promise
    return Promise.reject(error);
  }
);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
