import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { getApiUrl } from "../Utilities/api";

export default function ForgotPassword() {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [loading, setloading] = useState(false);
  const handleChange = (e) => {
    setEmail(e.target.value);
  };
  const [status, setStatus] = useState(null);
  const backToLogin = () => {
    navigate("/");
  };
  const formSubmit = async (e) => {
    e.preventDefault();
    // setLoading(true);
    try {
      const alert = document.body.getElementsByClassName("alert")[0];
      const loader = document.body.getElementsByClassName("loader")[0];
      const mainBody = document.body.getElementsByClassName("mainPage")[0];
      const bodyData = { email: email };
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      mainBody.style.opacity = "0.3";
      loader.removeAttribute("hidden");
      setloading(true);
      const response = await axios.post(
        getApiUrl("auth/forgot-password"),
        bodyData,
        { myHeaders }
      );
      setloading(false);
      mainBody.style.opacity = "1";
      loader.setAttribute("hidden", "true");
      // if (response.data.message != "Forgot password email sent successful") {
      //   throw new Error(response.data.message);
      // }
      alert.innerHTML =
        response.data.detail + " you will be redirected to the login page";
      // console.log(response.data.message);
      // console.log(response);
      alert.removeAttribute("hidden");
      setTimeout(() => {
        alert.setAttribute("hidden", "true");
      }, 2000);
      setTimeout(() => {
        navigate("/");
      }, 2000);
    } catch (error) {
      setloading(false);
      const alert = document.body.getElementsByClassName("alert")[0];
      const loader = document.body.getElementsByClassName("loader")[0];
      const mainBody = document.body.getElementsByClassName("mainPage")[0];
      // console.log(error);
      // console.log(error);
      console.log(error.response.data.detail[0].msg);
      if (error.response.data.detail[0].msg) {
        alert.innerHTML = error.response.data.detail[0].msg;
      } else {
        alert.innerHTML = error.response.data.detail;
      }
      alert.removeAttribute("hidden");
      mainBody.style.opacity = "1";
      loader.setAttribute("hidden", "true");
      setTimeout(() => {
        alert.setAttribute("hidden", "true");
      }, 2000);
      // console.log("Error Signing up: ", error);
    }
  };
  return (
    <>
      <div
        style={{
          position: "absolute",
          height: "100vh",
          width: "100vw",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        hidden={!loading}
      >
        <div
          className="fs-3 loader"
          style={{ position: "absolute", zIndex: "1000" }}
          hidden
        />
      </div>
      <div
        style={{ height: "100vh" }}
        className="d-flex justify-content-center align-items-center mainPage"
      >
        <p
          style={{
            position: "absolute",
            top: "12px",
            left: "24px",
            cursor: "pointer",
          }}
          className="fs-4"
          onClick={backToLogin}
        >
          <u>&lt; Login Page</u>
        </p>
        <div
          className="alert alert-primary alert"
          role="alert"
          style={{ position: "absolute", top: "0" }}
          hidden
        >
          A simple primary alert—check it out!
        </div>
        <div
          className="d-flex justify-content-center align-items-center border border-1 border-secondary-subtle rounded"
          style={{
            height: "35vh",
            width: "30vw",
            boxShadow: "20px 20px 20px rgba(0, 0, 0, 0.2)",
          }}
        >
          <form onSubmit={formSubmit}>
            <div className="mb-3">
              <p className="fs-2">
                <label for="exampleInputEmail1" className="form-label">
                  <b>Email address</b>
                </label>
              </p>
              <input
                type="email"
                className="form-control"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                onChange={handleChange}
                value={email}
              />
              <div id="emailHelp" className="form-text">
                Enter your registered email then click submit
              </div>
            </div>
            <button
              type="submit"
              className="btn btn-dark"
              style={{ width: "100px" }}
            >
              <b>SUBMIT</b>
            </button>
          </form>
        </div>
      </div>
    </>
  );
}
