// Config.js
import { initializeApp } from "firebase/app";
import {
  getAuth,
  GoogleAuthProvider,
  RecaptchaVerifier,
  signInWithPhoneNumber,
} from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyD77XL2a5SEN8PjWlHe6KNFPUubB8kOgnU",
  authDomain: "thedocforensics-af676.firebaseapp.com",
  projectId: "thedocforensics-af676",
  storageBucket: "thedocforensics-af676.appspot.com",
  messagingSenderId: "112686817814",
  appId: "1:112686817814:web:754fb0fc734ec537ca0b37",
  measurementId: "G-ZEHM8RYDCD",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const provider = new GoogleAuthProvider();

export { auth, provider, RecaptchaVerifier, signInWithPhoneNumber };
