import { useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
export default function Uploader() {
  // const fileInfo = document.querySelector(".file-info")
  // const[file,setFile]=useState('')
  // const[fileInf,setInf]=useState(fileInfo.innerHTML)
  // function handleChange(event)
  // {
  //     setFile(event.target.files[0].name)
  //     setInf(file)
  //     fileInfo.innerHTML = fileInf
  // }
  return (
    <>
      <div className="uploader" style={{ height: "fit-content" }}>
        <div
          style={{
            height: "20vh",
            width: "20vw",
            position: "relative",
            boxShadow: "20px 20px 20px rgba(0, 0, 0, 0.2)",
            border: "dashed",
            cursor: "pointer",
          }}
          className="d-flex justify-content-center align-items-center flex-column"
        >
          <p className="fs-5">
            <b>Upload Your Files Here.</b>
          </p>
          <form>
            <input
              type="file"
              className="input-field"
              style={{
                height: "100%",
                width: "100%",
                position: "absolute",
                top: "0",
                left: "0",
                cursor: "pointer",
                opacity: "0",
              }}
            />
          </form>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="40px"
            shape-rendering="geometricPrecision"
            text-rendering="geometricPrecision"
            image-rendering="optimizeQuality"
            fill-rule="evenodd"
            clip-rule="evenodd"
            viewBox="0 0 441 512.399"
          >
            <path
              fill="#88BCF4"
              fill-rule="nonzero"
              d="M102.778 354.886c-5.727 0-10.372-4.645-10.372-10.372s4.645-10.372 10.372-10.372h85.568a148.22 148.22 0 00-7.597 20.744h-77.971zm0-145.37c-5.727 0-10.372-4.645-10.372-10.372s4.645-10.372 10.372-10.372h151.288c5.727 0 10.373 4.645 10.373 10.372s-4.646 10.372-10.373 10.372H102.778zm0 72.682c-5.727 0-10.372-4.646-10.372-10.372 0-5.728 4.645-10.373 10.372-10.373h143.273c2.829 0 5.394 1.134 7.264 2.971a149.511 149.511 0 00-25.876 17.774H102.778z"
            />
            <path
              fill="#3C4D7A"
              d="M324.263 278.925c32.231 0 61.419 13.067 82.545 34.192C427.933 334.241 441 363.43 441 395.66c0 32.236-13.067 61.419-34.192 82.544-21.126 21.126-50.311 34.195-82.545 34.195-32.232 0-61.418-13.069-82.542-34.195-21.126-21.125-34.195-50.312-34.195-82.544s13.069-61.417 34.195-82.543c21.125-21.125 50.308-34.192 82.542-34.192zM60.863 0h174.81c3.382 0 6.384 1.619 8.278 4.123l110.107 119.12a10.288 10.288 0 012.746 7.011h.052v119.819a149.42 149.42 0 00-20.752-3.112v-92.212h-43.666v-.042h-.161c-22.046-.349-39.33-6.222-51.694-16.784-12.849-10.979-20.063-26.615-21.503-46.039a10.16 10.16 0 01-.096-1.405V20.752H60.863c-11.02 0-21.049 4.518-28.321 11.79-7.272 7.272-11.79 17.301-11.79 28.321v338.276c0 11.015 4.521 21.038 11.796 28.312 7.278 7.28 17.31 11.801 28.315 11.801h120.749a148.33 148.33 0 008.116 20.752H60.863c-16.73 0-31.958-6.849-42.987-17.881C6.852 431.099 0 415.882 0 399.139V60.863C0 44.114 6.846 28.894 17.87 17.87 28.894 6.846 44.114 0 60.863 0zm178.873 29.983v60.432c1.021 13.738 5.819 24.536 14.302 31.784 8.668 7.404 21.488 11.544 38.4 11.835v-.038h43.443L239.736 29.983zm24.119 381.587v-31.799c0-6.683 5.451-12.132 12.12-12.132h34.763v-22.387c.166-4.041 1.51-6.904 4.028-8.557 6.739-4.495 13.386 1.643 18.071 5.895l46.127 45.371c4.818 4.896 4.884 10.557 0 15.45l-47.393 46.461c-4.484 3.985-10.599 8.914-16.805 4.762-2.518-1.686-3.862-4.529-4.028-8.568V423.69h-34.763c-6.615 0-12.12-5.449-12.12-12.12z"
            />
          </svg>
        </div>
        <p className="my-3 file-info">File name is displayed here</p>
      </div>
    </>
  );
}
