import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import {
  auth,
  RecaptchaVerifier,
  signInWithPhoneNumber,
} from "../Utilities/Config";
import PhoneInput from "react-phone-input-2";

export default function PhoneNumberVerification() {
  const location = useLocation();
  const [password, setPassword] = useState(location.state || {});
  const [user, setUser] = useState(null);
  const [otp, setOtp] = useState("");
  // const [loading, setLoading] = useState(false);
  const sendOTP = async () => {
    try {
      const phoneNumber = "+" + password;
      const recaptcha = new RecaptchaVerifier(auth, "recaptcha", {});
      const confirmation = await signInWithPhoneNumber(
        auth,
        phoneNumber,
        recaptcha
      );
      setUser(confirmation);
    } catch (error) {
      console.log(error);
    }
  };
  const verifyOtp = async () => {
    try {
      const loader = document.body.getElementsByClassName("loader")[0];
      loader.removeAttribute("hidden");
      const data = await user.confirm(otp);
      loader.setAttribute("hidden", "true");
      console.log(data);
    } catch (error) {
      alert(error);
    }
  };
  const handlePassword = (e) => {
    setPassword(e.target.value);
  };
  const handleOtp = (e) => {
    setOtp(e.target.value);
    console.log(otp);
  };
  const handlePhoneNumberChange = (value) => {
    setPassword(value);
    console.log(password);
  };
  return (
    <>
      <div
        className="fs-3 loader"
        style={{ position: "absolute", top: "15%", left: "45%" }}
        hidden
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 200 200"
          height={"200px"}
        >
          <circle
            fill="#FF156D"
            stroke="#FF156D"
            strokeWidth="15"
            r="15"
            cx="40"
            cy="65"
          >
            <animate
              attributeName="cy"
              calcMode="spline"
              dur="2"
              values="65;135;65;"
              keySplines=".5 0 .5 1;.5 0 .5 1"
              repeatCount="indefinite"
              begin="-.4"
            ></animate>
          </circle>
          <circle
            fill="#FF156D"
            stroke="#FF156D"
            strokeWidth="15"
            r="15"
            cx="100"
            cy="65"
          >
            <animate
              attributeName="cy"
              calcMode="spline"
              dur="2"
              values="65;135;65;"
              keySplines=".5 0 .5 1;.5 0 .5 1"
              repeatCount="indefinite"
              begin="-.2"
            ></animate>
          </circle>
          <circle
            fill="#FF156D"
            stroke="#FF156D"
            strokeWidth="15"
            r="15"
            cx="160"
            cy="65"
          >
            <animate
              attributeName="cy"
              calcMode="spline"
              dur="2"
              values="65;135;65;"
              keySplines=".5 0 .5 1;.5 0 .5 1"
              repeatCount="indefinite"
              begin="0"
            ></animate>
          </circle>
        </svg>
      </div>
      <div style={{ height: "100vh" }} className="mainPage">
        <div
          style={{ height: "90vh" }}
          className="d-flex justify-content-center align-items-center"
        >
          <div className="container mt-5" style={{ width: "70vw" }}>
            <div className="row justify-content-center">
              <div className="col-md-6">
                <div className="card">
                  <div
                    className="card-body d-flex justify-content-center flex-column align-items-center"
                    style={{
                      padding: "0",
                      borderRadius: "6px",
                      boxShadow: "20px 20px 20px rgba(0, 0, 0, 0.2)",
                      border: "1px solid rgba(0, 0, 0, 0.2)",
                    }}
                  >
                    <div>
                      <PhoneInput
                        style={{ marginTop: "15px" }}
                        country={"in"}
                        value={password}
                        onChange={handlePhoneNumberChange}
                        placeholder="Enter your phone number"
                        inputStyle={{
                          width: "100%",
                          height: "50px", // Increase the height
                          paddingLeft: "40px", // Increase the padding
                          fontSize: "18px", // Increase the font size
                          borderRadius: "4px",
                          border: "1px solid #6C6A6A85",
                        }}
                      />
                      <button className="btn btn-dark my-2" onClick={sendOTP}>
                        Verify Phone number
                      </button>
                    </div>
                    <div id="recaptcha" className="my-3"></div>
                    <div>
                      <input
                        value={otp}
                        className="form-control"
                        placeholder="Enter OTP"
                        onChange={handleOtp}
                      />
                      <button className="btn btn-dark my-3" onClick={verifyOtp}>
                        Submit Otp
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
